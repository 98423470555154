import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47561b02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "search-warp" }
const _hoisted_3 = { class: "slot" }
const _hoisted_4 = { class: "search-box" }
const _hoisted_5 = ["placeholder", "onKeyup"]
const _hoisted_6 = { class: "search-nav" }
const _hoisted_7 = { class: "nav-list" }
const _hoisted_8 = ["onClick"]

import { ref, computed, reactive } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { useCity } from "@/pinia/api/useCity.js";
import { useSteelDynamic } from "@/pinia/api/steel-dynamic.js";
import { storeToRefs } from "pinia";

export default {
  props: {
  placeholder: String,
  default: () => "",
  type: String,
  setShowTitle: Function,
},
  emits: ["search", "companyChangeCity", "changeQy"],
  setup(__props, { expose, emit }) {

const props = __props

const cityStore = useCity();
cityStore.getCityData(); // 初始化city数据
const { city } = storeToRefs(cityStore);
// 向父元素发送事件

// props


const store = useSteelDynamic(); // 钢市动态的pinia
//修改关键字的方法
expose({
  setKeyWords(key) {
    keyWords.value = key;
  },
});

// 切换栏目的时候触发
const checkColumn = (row) => {
  store.column_id = row.id;
  store.getSteelDynamicData();
};
const route = useRoute();
const tabsState = reactive({
  // tabs
  tabsList: [
    { id: "0", name: "全部" },
    { id: "23", name: "宏观" },
    { id: "24", name: "黑色金属" },
    {
      id: "25",
      name: "能源",
    },
    {
      id: "26",
      name: "期货市场",
    },
  ],
  // 当前展示
  curTabs: computed(() => {
    const ind = tabsState.tabsList.findIndex((v) => v.id == store.column_id);
    return tabsState.tabsList[ind].id;
  }),
});

// 点击搜索的时候 触发的事件
const keyWords = ref("");
const search = () => {
  emit("search", keyWords);
};

// 所在区域
const changeQy = () => {
  if (cityDate.currentCityCode == -1) {
    cityDate.currentChildrenCityCode = "";
    emit("changeQy", false);
  }
};

// 保存关于下拉框 城市的数据
const cityDate = reactive({
  currentCityCode: "",
  children: [],
  currentChildrenCityCode: "",
});

// 当路由离开后
onBeforeRouteLeave(() => {
  /* store.clearParams();
  return true; */
});

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "date")
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "search",
          placeholder: props.placeholder,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((keyWords).value = $event)),
          onKeyup: _withKeys(search, ["enter"])
        }, null, 40, _hoisted_5), [
          [_vModelText, keyWords.value]
        ]),
        _createElementVNode("button", {
          class: "search-btn",
          onClick: search
        }, "搜索")
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("ul", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tabsState).tabsList, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass(item.id == _unref(tabsState).curTabs ? 'acitve-warp active' : ''),
            key: item.id,
            onClick: $event => (checkColumn(item))
          }, _toDisplayString(item.name), 11, _hoisted_8))
        }), 128))
      ])
    ])
  ]))
}
}

}